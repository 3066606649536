/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    span: "span",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Anniversaries are always a wonderful thing. Especially in an organisation full of people who give the best of themselves and spend their precious years creating innovative ways to lead the growth of a company."), "\n", React.createElement(_components.p, null, "Yelowsoft celebrated its third anniversary this year. So all the members of Yelowsoft family had decided to make it memorable by organising an event. Yelowsoft’s 3rd anniversary was celebrated on 4th February 2021."), "\n", React.createElement(_components.p, null, "All the Yelowsoft family members took part in it with great enthusiasm, fun and frolic. The energy and excitement level of Yelowsoft’s team members was evident in this event. Our CEO Mushahid Khatri along with the entire management team graced the occasion and gave it a headstart."), "\n", React.createElement(_components.h2, {
    id: "yelowsofts-journey-so-far"
  }, "Yelowsoft’s journey so far"), "\n", React.createElement(_components.p, null, "The anniversary celebration started with a brief overview of Yelowsoft. Our HR Manager Sujata Sagathia gave a walkthrough of Yelowsoft’s journey till date. Then our CEO shared how Yelowsoft had started its operations."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 62.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAYCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB0bKyzjICp//EABoQAAMAAwEAAAAAAAAAAAAAAAABAgQRMTL/2gAIAQEAAQUCtra4VkWLyf/EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/AWf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwEn/8QAFRABAQAAAAAAAAAAAAAAAAAAIGH/2gAIAQEABj8Ci//EABwQAAICAgMAAAAAAAAAAAAAAAABMUEQESFRcf/aAAgBAQABPyFKwXiluCjukXix/9oADAMBAAIAAwAAABCz3//EABURAQEAAAAAAAAAAAAAAAAAABEQ/9oACAEDAQE/ECz/xAAVEQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAgEBPxBk/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIUFhof/aAAgBAQABPxAtaEG+rRK3DRSuwMa6dNiLW/adJc1dL5Lw8n//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-celebrates-min\"\n        title=\"\"\n        data-src=\"/static/5e2f8e63f649f733af2c0a207896957c/a24e6/yelowsoft-celebrates-min.jpg\"\n        data-srcset=\"/static/5e2f8e63f649f733af2c0a207896957c/a6b4f/yelowsoft-celebrates-min.jpg 200w,\n/static/5e2f8e63f649f733af2c0a207896957c/a6407/yelowsoft-celebrates-min.jpg 400w,\n/static/5e2f8e63f649f733af2c0a207896957c/a24e6/yelowsoft-celebrates-min.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Also, he talked about Yelowsoft’s tremendous business growth by describing that the company is having its presence in 25+ countries by the end of 2020. He further added that Yelowsoft is having more than 2,00,000 drivers on board and a staff of more than 20 people."), "\n", React.createElement(_components.p, null, "Apart from that Yelowsoft has received more than 12,00,000 requests and served more than 5,00,000 customers till date."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/blog/yelowsoft-year-in-review-2020/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAHTsiQC/8QAGBABAQADAAAAAAAAAAAAAAAAAQIDEUH/2gAIAQEAAQUCxrV8DR//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwFn/8QAFhABAQEAAAAAAAAAAAAAAAAAAQIQ/9oACAEBAAY/AqF3/8QAGBABAQEBAQAAAAAAAAAAAAAAAQAhETH/2gAIAQEAAT8hxAHkmiLgX//aAAwDAQACAAMAAAAQjD//xAAVEQEBAAAAAAAAAAAAAAAAAAAAMf/aAAgBAwEBPxBX/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EBq//8QAGhABAQACAwAAAAAAAAAAAAAAAREAIUFRYf/aAAgBAQABPxB9TWACb8wngJuYRRh25//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-celebrates-cta1\"\n        title=\"\"\n        data-src=\"/static/c7a04dbed131935dc0e0eabb462491b6/a24e6/yelowsoft-celebrates-cta1.jpg\"\n        data-srcset=\"/static/c7a04dbed131935dc0e0eabb462491b6/a6b4f/yelowsoft-celebrates-cta1.jpg 200w,\n/static/c7a04dbed131935dc0e0eabb462491b6/a6407/yelowsoft-celebrates-cta1.jpg 400w,\n/static/c7a04dbed131935dc0e0eabb462491b6/a24e6/yelowsoft-celebrates-cta1.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "ceo-speech"
  }, "CEO Speech"), "\n", React.createElement(_components.p, null, "Along with this, our CEO had also explained which challenges they had to face while starting their operations. How did they manage to successfully overcome it. Apart from these, our CEO has discussed future strategies for the growth of Yelowsoft. Also, he appreciated the efforts of his team members with a thanksgiving speech."), "\n", React.createElement(_components.h2, {
    id: "product-consultant-speech"
  }, "Product Consultant Speech"), "\n", React.createElement(_components.p, null, "Then Yelowsoft’s product consultant Jafar Sheikh shared his experiences of working in the company. Also, he gave a further brief about the prospects of Yelowsoft. After then he ended his speech by thanking our CEO saying how he allowed him to grow in the organisation by putting trust in him."), "\n", React.createElement(_components.h2, {
    id: "its-cake-time"
  }, "It's cake time!"), "\n", React.createElement(_components.p, null, "There was a cake cutting session held after the speech. The cake was in the shape of Yelowsoft’s logo. It was a white coloured cake with a blue circle over it."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 62.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAF4ukNLLZX/xAAaEAACAwEBAAAAAAAAAAAAAAACAwABEhET/9oACAEBAAEFAmsIVLeZOhV0MV7Zqf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAARAQIQMf/aAAgBAQAGPwKyFKOklc//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEQMVFhsf/aAAgBAQABPyFCFuoAMCVVS2AC9QBDl8wf/9oADAMBAAIAAwAAABAMz//EABcRAQADAAAAAAAAAAAAAAAAAAEQESH/2gAIAQMBAT8QXaj/xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQIBAT8QwZ//xAAdEAACAwACAwAAAAAAAAAAAAABEQAhUTFhQYGh/9oACAEBAAE/EB1xpDhb+CbfAgAd75gwjrI+BFqK4LgqOSF3TIQIoEe5/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-celebrates-cake-1\"\n        title=\"\"\n        data-src=\"/static/bd24309424c4a9ba1d08a045e296112b/a24e6/yelowsoft-celebrates-cake-1.jpg\"\n        data-srcset=\"/static/bd24309424c4a9ba1d08a045e296112b/a6b4f/yelowsoft-celebrates-cake-1.jpg 200w,\n/static/bd24309424c4a9ba1d08a045e296112b/a6407/yelowsoft-celebrates-cake-1.jpg 400w,\n/static/bd24309424c4a9ba1d08a045e296112b/a24e6/yelowsoft-celebrates-cake-1.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Also, a golden coloured candle indicating Yelowsoft’s 3rd anniversary had been put in the centre of the cake. Our CEO blew the candles and cut the cake along with all the team members. All the Yelowsoft family members happily welcomed the cake cutting."), "\n", React.createElement(_components.h2, {
    id: "lets-click-a-picture"
  }, "Let’s click a picture!"), "\n", React.createElement(_components.p, null, "After the cake cutting session, a photo session was organised for all Yelowsoft family members. For this session, Yelowsoft had specially designed a navy blue t-shirt with its logo for the Yelowsoft family members."), "\n", React.createElement(Swiper, {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    navigation: true,
    pagination: {
      clickable: true
    },
    modules: [Autoplay, Navigation, Pagination],
    className: "blog-slider"
  }, React.createElement(SwiperSlide, null, React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 50%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAHtzjkqJysP/8QAGhABAAIDAQAAAAAAAAAAAAAAAgEEAAMxMv/aAAgBAQABBQI2SpFhSo3s5ya3dfn/xAAYEQEBAAMAAAAAAAAAAAAAAAABAAIDEf/aAAgBAwEBPwFstqPL/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQADEf/aAAgBAgEBPwEOxmJf/8QAHBAAAgEFAQAAAAAAAAAAAAAAABEBAhAhMUJR/9oACAEBAAY/AkT1DRLW/DBVb//EABoQAAIDAQEAAAAAAAAAAAAAAAERACExQXH/2gAIAQEAAT8hOAvc9lSDgUoYa5bRU1xXPTDJYeOETYuf/9oADAMBAAIAAwAAABBk3//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxBJKMX/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EA6iDt//xAAdEAEAAgIDAQEAAAAAAAAAAAABABEhQTFhcVGB/9oACAEBAAE/ED/aW+gsXT0X+wQcZUwU73DlaiBBijnqGPh8pECJei7pz7FSyvlb0T//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-celebrates1\"\n        title=\"\"\n        data-src=\"/static/8d9247f1e99a0c5ea8b52295a72a2c4c/a24e6/yelowsoft-celebrates1.jpg\"\n        data-srcset=\"/static/8d9247f1e99a0c5ea8b52295a72a2c4c/a6b4f/yelowsoft-celebrates1.jpg 200w,\n/static/8d9247f1e99a0c5ea8b52295a72a2c4c/a6407/yelowsoft-celebrates1.jpg 400w,\n/static/8d9247f1e99a0c5ea8b52295a72a2c4c/a24e6/yelowsoft-celebrates1.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), React.createElement(SwiperSlide, null, React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 50%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAXLlZcxGp//EABoQAAMBAAMAAAAAAAAAAAAAAAECAwQAIiP/2gAIAQEAAQUCXQzSOiwA0s3JjwuSGQ9f/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BZ//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGMf//EABwQAQACAQUAAAAAAAAAAAAAAAEAEQIQISJBUf/aAAgBAQAGPwK6LYcRPZ1CZF7af//EABsQAQADAQADAAAAAAAAAAAAAAEAETEhQVHB/9oACAEBAAE/ITq8AjFa8c9GFKc36gYGHyAVAGgyJTrP/9oADAMBAAIAAwAAABAoD//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPxDCv//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAgEBPxDSH//EAB0QAQACAgIDAAAAAAAAAAAAAAERIQAxQVFhofD/2gAIAQEAAT8QkmZIQJa31pxN0IDIoJ4vDSEgNJ839WBAI4zu2OauUg1oyKe7P//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-celebrates2\"\n        title=\"\"\n        data-src=\"/static/0a7edb50c3d70cc818c5bd9937be70e8/a24e6/yelowsoft-celebrates2.jpg\"\n        data-srcset=\"/static/0a7edb50c3d70cc818c5bd9937be70e8/a6b4f/yelowsoft-celebrates2.jpg 200w,\n/static/0a7edb50c3d70cc818c5bd9937be70e8/a6407/yelowsoft-celebrates2.jpg 400w,\n/static/0a7edb50c3d70cc818c5bd9937be70e8/a24e6/yelowsoft-celebrates2.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), React.createElement(SwiperSlide, null, React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 50%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB3vqMoJB//8QAGxAAAgEFAAAAAAAAAAAAAAAAAgMTAREyMzT/2gAIAQEAAQUCW2sq2XUByCGwedeP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BGf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAIBBQAAAAAAAAAAAAAAAAABAhAREoGh/9oACAEBAAY/App8Mi7RM1T/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRgbHw/9oACAEBAAE/IXG3nhqojstL8xQbcJo+uYuu09k//9oADAMBAAIAAwAAABBQ3//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/EKH/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QTJ//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhUTFBYcHw/9oACAEBAAE/EMTTwSyqye43jq9IEYsCcJv7UtFLpzXlCAAaJggwWn//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-celebrates3\"\n        title=\"\"\n        data-src=\"/static/9bac0e85ccde914f5403b3fb7b99c457/a24e6/yelowsoft-celebrates3.jpg\"\n        data-srcset=\"/static/9bac0e85ccde914f5403b3fb7b99c457/a6b4f/yelowsoft-celebrates3.jpg 200w,\n/static/9bac0e85ccde914f5403b3fb7b99c457/a6407/yelowsoft-celebrates3.jpg 400w,\n/static/9bac0e85ccde914f5403b3fb7b99c457/a24e6/yelowsoft-celebrates3.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), React.createElement(SwiperSlide, null, React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 50%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABdOOJsLRX/8QAGxAAAQQDAAAAAAAAAAAAAAAAAAECAxMSMTL/2gAIAQEAAQUCuepbIZTC7Gc//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAEFAQAAAAAAAAAAAAAAAAABAhAREiH/2gAIAQEABj8C4XqWiH//xAAbEAADAAMBAQAAAAAAAAAAAAAAAREhQVFhof/aAAgBAQABPyF5rpL7C+hWKoXC+wVQaGV+H//aAAwDAQACAAMAAAAQP/8A/8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQMBAT8QST//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QEX//xAAfEAEAAgEDBQAAAAAAAAAAAAABABEhQVFxYYGhwdH/2gAIAQEAAT8QXWJaCqL57gWyNCha8ad4q8ck3YBwBxpPJa9WolStrl4n/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-celebrates4\"\n        title=\"\"\n        data-src=\"/static/1486e77b928c22431fd92783d1e4bfe5/a24e6/yelowsoft-celebrates4.jpg\"\n        data-srcset=\"/static/1486e77b928c22431fd92783d1e4bfe5/a6b4f/yelowsoft-celebrates4.jpg 200w,\n/static/1486e77b928c22431fd92783d1e4bfe5/a6407/yelowsoft-celebrates4.jpg 400w,\n/static/1486e77b928c22431fd92783d1e4bfe5/a24e6/yelowsoft-celebrates4.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })))), "\n", React.createElement(_components.p, null, "Yelowsoft’s employees were posing cheerfully for the photos. All the members had expressed their joy in a fun way. Photos of the office premises were also taken along with the team’s photo shoot."), "\n", React.createElement(_components.h2, {
    id: "its-time-for-tasty-snacks"
  }, "It's time for tasty snacks!"), "\n", React.createElement(_components.p, null, "There is no good time to have snacks when everybody is enjoying the day merrily. Thus, at the end of this event, Yelowsoft family offered snacks to all its members. This foundation day celebration had prospered a great camaraderie among all the members of Yelowsoft family. This evening gave us some cherishable lifetime moments."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/contact/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGoLMoM3//EABgQAAIDAAAAAAAAAAAAAAAAAAEDAjIz/9oACAEBAAEFAoklj82X/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEAAwAAAAAAAAAAAAAAAAAAAhAx/9oACAECAQE/ASq//8QAFxABAQEBAAAAAAAAAAAAAAAAAAERgf/aAAgBAQAGPwKzXVf/xAAZEAACAwEAAAAAAAAAAAAAAAAAARExQXH/2gAIAQEAAT8heBoUl/Aqr0//2gAMAwEAAgADAAAAEHQv/8QAFREBAQAAAAAAAAAAAAAAAAAAEEH/2gAIAQMBAT8Qh//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxAAMlv/xAAZEAEAAwEBAAAAAAAAAAAAAAABABFxITH/2gAIAQEAAT8Qb05DGAq8EWthGAWmp//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-celebrates-cta2\"\n        title=\"\"\n        data-src=\"/static/3e3caf5a9c62c00c2f134553f8a6c609/a24e6/yelowsoft-celebrates-cta2.jpg\"\n        data-srcset=\"/static/3e3caf5a9c62c00c2f134553f8a6c609/a6b4f/yelowsoft-celebrates-cta2.jpg 200w,\n/static/3e3caf5a9c62c00c2f134553f8a6c609/a6407/yelowsoft-celebrates-cta2.jpg 400w,\n/static/3e3caf5a9c62c00c2f134553f8a6c609/a24e6/yelowsoft-celebrates-cta2.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
